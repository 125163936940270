import React from "react"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Articles from "../components/Articles"
import "./platform.scss"

const Platform = () => {
  // const [tabs, setTabs] = React.useState(1)
  const data = useStaticQuery(graphql`
    query {
      ph: file(relativePath: { eq: "P_Banner1@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pm: file(relativePath: { eq: "P_Banner2@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 784) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gt: file(relativePath: { eq: "P_GT_profile@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 430) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gc: file(relativePath: { eq: "GARETH_BW@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 763) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      epl: file(relativePath: { eq: "EP_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [openContact, SetOpenContact] = React.useState(false)

  return (
    <Layout formTrigger={openContact} cb={SetOpenContact}>
      <SEO title="Platform" />
      <section className="fold1 toneDown">
        <div className="container">
          <div className="leftCol col">
            {/* <span className="epLogo"></span> */}
            <div className="">
              <Img
                style={{
                  width: "180px",
                  height: "auto",
                }}
                className="epLogo"
                fluid={data.epl.childImageSharp.fluid}
                alt="Expertpoint logo"
              />
            </div>
            <p>
              Our versatile new digital platform for pin-point precise executive
              coaching.
            </p>
            <a href="#howItWorks" className="round pink">
              How it works {">"}{" "}
            </a>
          </div>
          <div className="rightCol col">
            <Img
              style={{
                width: "600px",
                height: "488px",
              }}
              className="soloHero"
              fluid={data.ph.childImageSharp.fluid}
              alt="goldsmith"
            />
          </div>
        </div>
      </section>
      <section className="fold2 f-platform">
        <div className="container">
          <h2>
            Reboot. Cultures. <br />
            Redefine. Business models. <br />
            Reconfigure. Operating models. <br />
            Redimension. Organizations. <br />
          </h2>
          <p>
            In these volatile, uncertain, complex & ambiguous times, you need <b>100% virtual access</b> to <b>result oriented, modular & price conscious</b> tools & frameworks. ‘Expert<b>point</b>’ provides executive coaching from the world’s top experts, to help your organization cope with the ‘new business normal.’
            <br />
            <br />
            Empower your senior business leaders to <b>learn & collaborate virtually</b>. Choose the <b>best curated tools & frameworks</b>. Our services are <b>transparent & fixed price</b>, with <b>clearly detailed tasks & outputs</b>, delivered by <b>top experts</b> and <b>contractually assured & managed</b> by us.
          </p>
        </div>
        <div className="dottedWhite"></div>
      </section>
      <section className="platformTabs">
        <div className="container">
          <div className="col leftCol">
            <h1>Platform</h1>
            {/* <span className="epLogo"></span> */}
            <div className="">
              <Img
                className="epLogo"
                fluid={data.epl.childImageSharp.fluid}
                alt="Expertpoint logo"
              />
            </div>
            <p>
              Empower your business leaders <br />
              to thrive, by becoming ‘adaptable’ <br />
              with a robust ‘Learn. Do. Review.’ <br />
              result oriented coaching system.
            </p>
            <b>5 distinct executive coaching advantages!</b>
            <ul>
              <li>Constantly transformative and adaptive digital platform.</li>
              <li>
                Curation of the best tools and frameworks, anytime, virtually.
              </li>
              <li>Mentored by hand-picked top experts and specialists.</li>
              <li>Easy to use ‘Learn. Do. Review.’ coaching format.</li>
              <li>Collaborative platform to encourage teamwork.</li>
            </ul>
          </div>
          <div className="col rightCol">
            {/* <div className="tabsContainer">
              <div
                onClick={() => setTabs(1)}
                className={tabs === 1 ? "tab active" : "tab"}
              >
                Learn
              </div>
              <div
                onClick={() => setTabs(2)}
                className={tabs === 2 ? "tab active" : "tab"}
              >
                Do
              </div>
              <div
                onClick={() => setTabs(3)}
                className={tabs === 3 ? "tab active" : "tab"}
              >
                Review
              </div>
            </div> */}
            <Img
              style={{
                width: "600px",
                height: "400px",
              }}
              className="soloHero"
              fluid={data.pm.childImageSharp.fluid}
              alt="goldsmith"
            />
          </div>
        </div>
      </section>
      <section id="howItWorks" className="hiw">
        <div className="container">
          <h1>How it works</h1>
          <div className="hiw timeline">
            <div className="ht-item">
              <span className="ht-num">1</span>
              <h2>
                Onboarding <br /> Process
              </h2>
              <p>
                As a first step, every team member signs up on Expertpoint
              </p>
            </div>
            <div className="ht-item">
              <span className="ht-num">2</span>
              <h2>
                Learning <br /> Modules
              </h2>
              <p>
                All team members receive access to the relevant learning materials.
              </p>
            </div>
            <div className="ht-item">
              <span className="ht-num">3</span>
              <h2>
                Complete <br /> Exercises
              </h2>
              <p>
                Each team member does exercises to strengthen their learnings.
              </p>
            </div>
            <div className="ht-item">
              <span className="ht-num">4</span>
              <h2>
                Call with <br /> Expert
              </h2>
              <p>
                Our top expert reviews the work and imparts long term knowledge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="gtPreview">
        <div className="container">
          <div className="col colLeft">
            <h1>Top Expert</h1>
            <p className="subtitle1">
              Empower your business leaders
              to lead teams with confidence.
              Personally coached & mentored
              by a world class military expert.
            </p>
            <p className="subtitle2">
              With ‘Expert<b>point</b>’ your senior business leaders will be guided and mentored by top experts, who are highly successful and experienced professionals with a proven track record of executive coaching. Our first top expert is <b>Gareth Tennant</b>.
            </p>
            <ul>
              <li>
                Former head of operational intelligence for the Royal Marines, UK.
              </li>
              <li>
                Specialized in intelligence, surveillance and reconnaissance operations.
              </li>
              <li>
                Global leader in the art of information exploitation.
              </li>
              <li>
                Supports business leaders in the art of decision making and complex environments.
              </li>
            </ul>
          </div>
          <div className="col colRight">
            <Img
              style={{
                width: "auto",
                height: "420px",
              }}
              className="spot_shadow"
              fluid={data.gt.childImageSharp.fluid}
              alt="Gareth Tennant"
            />
          </div>
        </div>
      </section>
      <section className="gtCourse">
        <div className="container">
          <div className="col colLeft">
            <h2>
              Train your business leaders
              to apply strategies & tactics,
              adapted from military thinking,
              to achieve business goals.
            </h2>
            <p>
              The parallels between the challenge of doing
              business today & running a combat operation
              are surprisingly similar. That’s why a military
              expert is the perfect coach & mentor!
            </p>
            <h3>
              Wargaming in Complex Environment
            </h3>
            <h5>4-Week Impactful Leadership Program</h5>
            <a href="/workshops" className="pink round">
              Learn more {">"}
            </a>
          </div>
          <div className="col colRight">
            <Img
              className="spot_shadow gt gareth_img"
              fluid={data.gc.childImageSharp.fluid}
              alt="Gareth Tennant"
            />
          </div>
        </div>
      </section>
      {/* <Articles /> */}
    </Layout>
  )
}

export default Platform
